import React from 'react';
import Header from '../comp_dashboard/header';
import Sidebar from '../comp_dashboard/Sidebar';
import ShowModal from '../enc_datos_generales/ModalCreateEncuesta';
import {useState} from 'react'

const DashboardComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className='mt-2 row'>
        <>
<Header />
  </>
  <Sidebar></Sidebar>
      <div className='cuadro_princal'>
        <div className='parent-label titulo_cuadro'>Encuesta: PRESTACION SERVICIOS, INSTALACIONES Y DIGITALIZACION </div>
        {/* Aquí puedes agregar más contenido si es necesario */}
        <ShowModal className='parent-rigth btn btn-success btn-ladda' isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

      </div>

    </div>
  );
};

export default DashboardComponent;
