import React, { useState } from 'react';
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaCocktail,
    FaCalendarAlt,
    FaMusic,
    FaCity,
    FaUsersCog,
    FaUserShield,
    FaRoute,
    FaRegStar,
    FaClipboardCheck,
    FaHandsHelping,
    FaMoneyBillWave,
    FaPeopleCarry,
    FaPhotoVideo
    

}from "react-icons/fa";
import { NavLink } from 'react-router-dom';


const Sidebar = ({children}) => {
    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);
    const menuItem=[
        {
            path:"/principal",
            name:"Dashboard",
            icon:<FaTh/>
        },
        {
            path:"/viewenc",
            name:"Datos Generales",
            icon:<FaUserAlt/>
        },
        {
            path:"/viewhor",
            name:"Horarios",
            icon:<FaCalendarAlt/>
        },
        {
            path:"/viewamb",
            name:"Ambiente",
            icon:<FaMusic/>
        },
        {
            path:"/viewins",
            name:"Instalaciones/Funcionamiento",
            icon:<FaCity/>
        },
        {
            path:"/viewseg",
            name:"Seguridad",
            icon:<FaUserShield/>
        },
        {
            path:"/viewent",
            name:"Entorno",
            icon:<FaRoute/>
        },
        {
            path:"/viewser",
            name:"Servicios",
            icon:<FaClipboardCheck/>
        },
        {
            path:"/viewesp",
            name:"Espectaculos",
            icon:<FaCocktail/>
        },
        {
            path:"/viewpers",
            name:"Personal",
            icon:<FaHandsHelping/>
        },
        {
            path:"/viewmar",
            name:"Marketing",
            icon:<FaPhotoVideo/>
        },
        {
            path:"/viewprec",
            name:"Precios",
            icon:<FaMoneyBillWave/>
        },
        {
            path:"/viewrscbuena",
            name:"RSC-BuenasPrácticas",
            icon:<FaUsersCog/>
        },
        {
            path:"/viewrscmala",
            name:"RSC-MalasPrácticas",
            icon:<FaPeopleCarry/>
        },
        {
            path:"/viewval",
            name:"Valoración",
            icon:<FaRegStar/>
        }
    ]
    return (
        <div >
           <div style={{width: isOpen ? "200px" : "50px"}} className="sidebar">
               <div className="top_section">
                   <div style={{marginLeft: isOpen ? "0px" : "0px"}} className="bars">
                       <FaBars onClick={toggle}/>
                   </div>
               </div>
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
           </div>
           <main>{children}</main>
        </div>
    );
};

export default Sidebar;

