import React from 'react';
import '../App.css';
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import CreateEntorno from './CreateEntorno';

class ModalCreateEntorno extends React.Component{
  state={
    abierto: false,
  }

  abrirModal=()=>{
    this.setState({abierto: !this.state.abierto});
  }

  render(){

    const modalStyles = {
        width: "80%", // Ajusta el ancho del modal
        maxWidth: "800px", // Establece el ancho máximo del modal
      };
      
    return(
      <>
      <div className="principal">
        <div className="secundario">
          <Button className='parent-rigth btn btn-success btn-ladda' color="success" onClick={this.abrirModal}>Agregar Entorno</Button>

        </div>
      </div>

      <Modal isOpen={this.state.abierto} style={modalStyles}>
        
        <ModalBody>
        <>
          <CreateEntorno />
            </>
        </ModalBody>

      </Modal>
      </>
    )
  }
}

export default ModalCreateEntorno;